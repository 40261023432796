<template>
  <v-app>
    <v-content class="fullBg">
      <v-container class="fill-height" fluid>
        <a class="navbar-brand" href="/home.php"
          ><img
            src="/assets/images/FinSupport_logo.png"
            alt=""
            class="img-fluid"
        /></a>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{
                  $t("register.page title")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  @keyup.native.enter="register"
                >
                  <v-text-field
                    id="first_name"
                    v-model="form.first_name"
                    :label="$t('users.FirstName')"
                    name="first_name"
                    prepend-icon="person"
                    type="text"
                    :rules="rules.first_name"
                    :disabled="sending"
                    @input="serverErrors = null"
                  />
                  <v-text-field
                    id="first_name"
                    v-model="form.last_name"
                    :label="$t('users.LastName')"
                    name="last_name"
                    prepend-icon="person"
                    type="text"
                    :rules="rules.last_name"
                    :disabled="sending"
                    @input="serverErrors = null"
                  />
                  <v-text-field
                    id="email"
                    v-model="form.email"
                    :label="$t('register.email')"
                    name="email"
                    prepend-icon="email"
                    type="text"
                    :rules="rules.email"
                    :disabled="sending"
                    @input="serverErrors = null"
                  />
                  <v-text-field
                    id="password"
                    v-model="form.password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('register.password')"
                    name="password"
                    prepend-icon="lock"
                    :type="showPass ? 'text' : 'password'"
                    counter
                    :rules="rules.password"
                    :disabled="sending"
                    @click:append="showPass = !showPass"
                    @input="serverErrors = null"
                  />
                  <v-text-field
                    id="password"
                    v-model="form.password_confirmation"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('register.password confirmation')"
                    name="password"
                    prepend-icon="lock"
                    :type="showPass ? 'text' : 'password'"
                    counter
                    :rules="rules.password_confirmation"
                    :disabled="sending"
                    @click:append="showPass = !showPass"
                    @input="serverErrors = null"
                  />
                  <v-text-field
                    id="company"
                    v-model="form.company"
                    :label="$t('users.Company')"
                    name="company"
                    prepend-icon="domain"
                    type="text"
                    :rules="rules.company"
                    :disabled="sending"
                    @input="serverErrors = null"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="register">{{
                  $t("register.register")
                }}</v-btn>
              </v-card-actions>
              <v-progress-linear
                v-if="sending"
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <v-alert v-if="errorMessage" tile class="mt-5" type="error">{{
                errorMessage
              }}</v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        serviceid: this.serviceid
      },
      rules: {
        password: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("register.password"),
            }),
          (v) =>
            v
              ? v.length >= 8 || this.$t("validation.min.string", { min: 8 })
              : "Required.",
          () =>
            this.serverErrors && this.serverErrors.password
              ? this.$t(this.serverErrors.password[0], {
                  name: this.$t("register.password"),
                })
              : true,
        ],
        password_confirmation: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("register.password confirmation"),
            }),
          (v) =>
            v
              ? v.length >= 8 || this.$t("validation.min.string", { min: 8 })
              : "Required.",
          () =>
            this.serverErrors && this.serverErrors.password_confirmation
              ? this.$t(this.serverErrors.password_confirmation[0], {
                  name: this.$t("register.password confirmation"),
                })
              : true,
        ],
        email: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("register.email"),
            }),
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Invalid e-mail.";
          },
          () =>
            this.serverErrors && this.serverErrors.email
              ? this.$t(this.serverErrors.email[0], {
                  name: this.$t("register.email"),
                })
              : true,
        ],
        first_name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("users.FirstName"),
            }),
          () =>
            this.serverErrors && this.serverErrors.first_name
              ? this.serverErrors.first_name[0]
              : true,
        ],
        last_name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("users.LastName"),
            }),
          () =>
            this.serverErrors && this.serverErrors.last_name
              ? this.serverErrors.last_name[0]
              : true,
        ],
        company: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("users.Company"),
            }),
          () =>
            this.serverErrors && this.serverErrors.company
              ? this.serverErrors.company[0]
              : true,
        ],
      },
      showPass: false,
      errorMessage: null,
      serverErrors: null,
      sending: false,
      valid: false,
    };
  },
  mounted() {
    let name = "serviceid=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        this.form.serviceid = c.substring(name.length, c.length);
        break;
      }
    }
    this.form.serviceid = "";
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    register() {
      if ("Notification" in window && Notification.permission !== "denied") {
        Notification.requestPermission();
      }
      this.errorMessage = null;
      this.serverErrors = null;
      if (this.$refs.form.validate()) {
        this.apiRegistration().catch((error) => {
          this.errorMessage = error.message;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
        });
      }
    },
  },
};
</script>

<style scoped>
.fullBg {
  background-color: rgb(21, 21, 24);
  background-image: url("~@/assets/login.png");
  background-size: contain;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.img-fluid {
  max-height: 100px;
  width: 320px;
  float: left;
  top: 20px;
  position: absolute;
}
</style>
